.navigation{
    width: 100%;
    height: 70px;
    background: var(--bgColor);
    display: flex;
    align-items: center;
  }
  
  .logo{
    color: var(--whiteColor);
    font-size: 2.4rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
  }